/**
 * @description Handle ViewMore button behaviour and show hidden refinement after click on it.
 * @param {typeof import('widgets/Widget').default} Widget Base widget for extending
 */

export default function (Widget) {
    return class PDFiltersMgr extends Widget {
        prefs() {
            return {

                ...super.prefs() //extend preferences from parent widget class
            };
        }
        init = async () => {
            console.log('init filters widget 3');
            this.initFilterGrid();
            console.log('loaded');
            document.addEventListener("DOMContentLoaded", (event) => {
            
                // this.loadFilterLibrary()
                    
            });
        }
        filterClick = (event) => {
            console.log('click clack', event.get(), event.data('filter').replace(/\s/g, ""));
            let filterValue = event.data('filter').replace(/\s/g, "");
            if (filterValue == 'ViewAll' || filterValue == 'all') {
                this.iso.arrange({
                filter: '*'
            })
            } else {
                this.iso.arrange({
                    filter: '.' + filterValue
                })
            }

        }
        loadFilterLibrary = ()=> {
            const url = "https://unpkg.com/isotope-layout@3/dist/isotope.pkgd.min.js";
            const scripts = document.getElementsByTagName('script');
            for (var i = scripts.length; i--;){
                if (scripts[i].src == url) {
                    return true;
                }
            }
            const script = document.createElement('script');
            script.src = url;
            script.async = false;
            document.head.appendChild(script);
        }
        initFilterGrid = ()=> {
            console.log('init grid');
            var elem = document.querySelector('.experience-filterContent');
            elem.querySelectorAll('.experience-commerce_assets-filterCard .filterItem').forEach(element => {
                console.log('test', element);
                const classes = element.getAttribute('data-filtername').split(',');
                classes.forEach(className => {
                    element.parentNode.classList.add(className.replace(/\s/g, ""));
                });
               
            });
            this.iso = new window.Isotope( elem, {
            itemSelector: '.experience-commerce_assets-filterCard',
            percentPosition: true,
            masonry: {
                // use element for option
                columnWidth: '.experience-commerce_assets-filterCard',
                gutter: 16
            }
            });
            
        }
    }
}