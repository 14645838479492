/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable sonarjs/no-duplicate-string */
/* sonar-ignore-start */

window.$ = require('jquery');
require('./collapsibleItem');
require('./sfccStoreLocator');
require('./updateSocialLoginURL');
const [, pdp, clp, plp, wishlist, cart, checkout, orderconfirmation, account, , page, storeLocator, corporatepayment, corporatepaymentlink] = [
    'home',
    'pdp',
    'clp',
    'plp',
    'wishlist',
    'cart',
    'checkout',
    'orderconfirmation',
    'account',
    'static',
    'page',
    'storeLocator',
    'corporatepayment',
    'corporatepaymentlink'
]
// @ts-ignore
    .map(ctx => window.contexts.includes(ctx));

import WidgetsMgr from 'widgets/widgetsMgr';
import AccessibilityFocusTrapMixin from 'widgets/global/AccessibilityFocusTrapMixin';
import AccessibilityFocusMixin from 'widgets/global/AccessibilityFocusMixin';
import SearchBox from 'widgets/search/SearchBox';
import Modal from 'widgets/global/Modal';
import SideBar from 'harmony/global/SideBar';
import MixMiniCart from 'harmony/cart/MixMiniCart';
import VideoPlayer from 'harmony/global/VideoPlayer';
import CarouselSwiper from 'widgets/global/CarouselSwiper';
import ExtendedCarouselSwiper from 'harmony/global/ExtendedCarouselSwiper';
import HarmonyCarousel from 'harmony/global/HarmonyCarousel';
import EinsteinCarousel from 'harmony/global/EinsteinCarousel';
import ShipToTitle from 'harmony/ShipToTitle';
import ShipToStates from 'harmony/ShipToStates';
import ProductPositionMapping from 'harmony/ProductPositionMapping';
import HarmonyStickyHeader from 'harmony/global/HarmonyStickyHeader';
import HarmonyHeader from 'harmony/global/HarmonyHeader';
import HarmonyMenuPanel from 'harmony/global/HarmonyMenuPanel';
import HarmonyHamburgerMenuItem from 'harmony/global/HarmonyHamburgerMenuItem';
import CookieDismissBlock from 'harmony/global/CookieDismissBlock';
import Overlay from 'harmony/global/Overlay';
import Loader from 'harmony/global/Loader';
import HarmonySearchBox from 'harmony/search/HarmonySearchBox';
import HarmonyQuantityStepper from 'harmony/forms/HarmonyQuantityStepper';
import BrowsingOnlyModal from 'harmony/global/BrowsingOnlyModal';
import HarmonyModal from 'harmony/global/HarmonyModal';
import shipToSideBar from 'harmony/global/ShipToSideBar';
import HeaderSideBar from 'harmony/global/HeaderSideBar';
import ViewMore from 'harmony/global/ViewMore';
import SecureCheckoutButton from 'harmony/cart/SecureCheckoutButton';
import ShippingSelector from 'harmony/cart/ShippingSelector';
import HarmonyBasicInput from 'harmony/forms/HarmonyBasicInput';
import ChangePasswordForm from 'harmony/forms/ajax/ChangePasswordForm';
import RegisterForm from 'harmony/forms/ajax/RegisterForm';
import ProfileDetailsForm from 'harmony/account/ProfileDetailsForm';
import Cognito from 'harmony/aws/Cognito';
import HarmonyPasswordResetForm from 'harmony/forms/ajax/HarmonyPasswordResetForm';
import GeolocationConsent from 'harmony/GeolocationConsent';
import HarmonyAccordionItem from 'harmony/global/HarmonyAccordionItem';
import HarmonyBackToTop from 'harmony/global/HarmonyBackToTop';
import HarmonyFocusHighlighter from 'harmony/global/HarmonyFocusHighlighter';
import HarmonyGlobalAlerts from 'harmony/global/HarmonyGlobalAlerts';
import HarmonyConsentTracking from 'harmony/global/HarmonyConsentTracking';
import HarmonyAjaxFormResponseContainer from 'harmony/forms/HarmonyAjaxFormResponseContainer';
import HarmonyAddToWishlistMixin from 'harmony/wishlist/HarmonyAddToWishlistMixin';
import HarmonyButton from 'harmony/global/HarmonyButton';
import HarmonyEmitBusEvent from 'harmony/global/HarmonyEmitBusEvent';
import QuickAddToCart from 'harmony/product/QuickAddToCart';
import ProcessButton from 'widgets/global/ProcessButton';
import HarmonyAjaxForm from 'harmony/forms/HarmonyAjaxForm';
import HarmonyInputPassword from 'harmony/forms/HarmonyInputPassword';
import HarmonyEmailSubscribe from 'harmony/forms/ajax/HarmonyEmailSubscribe';
import ShipToError from 'harmony/product/ShipToError';
import InputBirthdayDate from 'harmony/forms/InputBirthdayDate';
import StatesInputRadio from 'harmony/forms/StatesInputRadio';
import cancelOrderModal from 'harmony/cancelOrderModal';
import HarmonyLoginForm from 'harmony/forms/ajax/HarmonyLoginForm';
import ListAccessibility from 'widgets/global/ListAccessibility';
import LoginForm from 'widgets/forms/ajax/LoginForm';
import VerificationCodeForm from 'harmony/account/VerificationCodeForm';
import ReserveBarMgr from './reserveBar/ReserveBarMgr';
import ReserveBarCart from './reserveBar/ReserveBarCart';
import ReserveBarSearch from './reserveBar/ReserveBarSearch';

// TODO:HARMONY:BE: Remove InputNumber here when Boilerplate will complete it.
// As for now it is not in widgetsList.js
import InputNumber from 'widgets/forms/InputNumber';
import ReserveBarProduct from './reserveBar/ReserveBarProduct';
import PDFiltersMgr from './experience/filters';




WidgetsMgr.addWidgetsList('app_harmony', () => [
    ['accessibilityFocusTrapMixin', AccessibilityFocusTrapMixin],
    ['sideBar', SideBar, 'accessibilityFocusTrapMixin'],
    ['button', HarmonyButton, 'button'],
    ['ajaxform', HarmonyAjaxForm, 'ajaxform'],
    ['videoPlayer', VideoPlayer],
    ['carouselSwiper', CarouselSwiper],
    ['carouselSwiper', ExtendedCarouselSwiper, 'carouselSwiper'],
    ['shipToStates', ShipToStates, 'ajaxform'],
    ['productPositionMapping', ProductPositionMapping],
    ['shipToTitle', ShipToTitle, 'emitBusEvent'],
    ['mixMiniCart', MixMiniCart, 'minicart'],
    ['carousel', HarmonyCarousel, 'carousel'],
    ['einsteinCarousel', EinsteinCarousel, 'carousel'],
    ['inputNumber', InputNumber, 'basicInput'],
    ['harmonyQuantityStepper', HarmonyQuantityStepper, 'inputNumber'],
    ['stickyHeader', HarmonyStickyHeader, 'stickyHeader'],
    ['harmonyHeader', HarmonyHeader],
    ['loginForm', LoginForm, 'ajaxform'],
    ['harmonyLoginForm', HarmonyLoginForm, 'loginForm'],
    ['harmonyLoginForm', ListAccessibility, 'harmonyLoginForm'],
    ['harmonyMenuPanel', HarmonyMenuPanel, 'menuPanel'],
    ['hamburgerMenuItem', HarmonyHamburgerMenuItem, 'hamburgerMenuItem'],
    ['cookieDismissBlock', CookieDismissBlock],
    ['overlay', Overlay],
    ['loader', Loader],
    ['harmonySearchBox', SearchBox, 'combobox'],
        ['harmonySearchBox', AccessibilityFocusTrapMixin, 'harmonySearchBox'],
        ['harmonySearchBox', Modal, 'harmonySearchBox'],
        ['harmonySearchBox', HarmonyModal, 'harmonySearchBox'],
        ['harmonySearchBox', HarmonySearchBox, 'harmonySearchBox'],
    ['browsingOnlyModal', BrowsingOnlyModal, 'confirmDialog'],
    ['modal', HarmonyModal, 'modal'],
    ['shipToSideBar', shipToSideBar, 'sideBar'],
    ['headerSideBar', HeaderSideBar, 'sideBar'],
    ['secureCheckoutButton', SecureCheckoutButton, 'button'],
    ['shippingSelector', ShippingSelector, 'inputSelect'],
    ['viewMore', ViewMore],
    ['basicInput', HarmonyBasicInput, 'basicInput'],
    ['changePasswordForm', ChangePasswordForm, 'ajaxform'],
    ['cognito', Cognito],
    ['harmonyPasswordResetForm', HarmonyPasswordResetForm, 'ajaxform'],
    ['harmonyPasswordResetForm', AccessibilityFocusMixin, 'harmonyPasswordResetForm'],
    ['registerForm', RegisterForm, 'ajaxform'],
    ['verificationCodeForm', VerificationCodeForm, 'ajaxform'],
    ['verificationCodeForm', ListAccessibility, 'verificationCodeForm'],
    ['geolocationConsent', GeolocationConsent],
    ['accordionItem', HarmonyAccordionItem, 'accordionItem'],
    ['globalAlerts', HarmonyGlobalAlerts, 'globalAlerts'],
    ['backtotop', HarmonyBackToTop, 'backtotop'],
    ['focusHighlighter', HarmonyFocusHighlighter, 'focusHighlighter'],
    ['harmonyConsentTracking', HarmonyConsentTracking, 'consentTracking'],
    ['productTile', HarmonyAddToWishlistMixin, 'productTile'],
    ['harmonyAjaxFormResponseContainer', HarmonyAjaxFormResponseContainer, 'ajaxFormResponseContainer'],
    ['emitBusEvent', HarmonyEmitBusEvent, 'emitBusEvent'],
    ['quickAddToCart', QuickAddToCart],
    ['processButton', ProcessButton, 'button'],
    ['emailSubscribe', HarmonyEmailSubscribe, 'emailSubscribe'],
    ['inputPassword', HarmonyInputPassword, 'inputPassword'],
    ['shipToError', ShipToError],
    ['inputBirthdayDate', InputBirthdayDate, 'inputText'],
    ['statesInputRadio', StatesInputRadio, 'inputRadio'],
    ['cancelOrderModal', cancelOrderModal, 'harmonyModal'],
    ['reserveBarMgr', ReserveBarMgr],
    ['reserveBarCart', ReserveBarCart],
    ['reserveBarSearch', ReserveBarSearch], 
    ['reserveBarProduct', ReserveBarProduct],
    ['pdFiltersMgr', PDFiltersMgr]

]);

if (corporatepayment) {
    require('./corporatePayment');
}

if (corporatepaymentlink) {
    require('./corporatepaymentlink');
}

if (plp) {
    WidgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'harmony.search.widgets' */ 'harmony/search/HarmonyRefinementsPanel'),
        import(/* webpackChunkName: 'harmony.search.widgets' */ 'harmony/search/HarmonyProductListingMgr'),
        import(/* webpackChunkName: 'harmony.search.widgets' */ 'harmony/search/HarmonyRefinement')
    ]).then(deps => {
        const [
            HarmonyRefinementsPanel,
            HarmonyProductListingMgr,
            HarmonyRefinement
        ] = deps.map(dep => dep.default);

        return {
            listId: 'harmony.search.widgets',
            widgetsDefinition: () => [
                // listAccessibility
                ['harmonyRefinementsPanel', HarmonyRefinementsPanel, 'modal'],
                ['harmonyProductListingMgr', HarmonyProductListingMgr, 'productListingMgr'],
                ['harmonyRefinement', HarmonyRefinement, 'refinement']
            ]
        };
    }));
}

if (!checkout) {
    WidgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'harmony.minicart' */ 'harmony/cart/HarmonyMiniCartDialog')
    ]).then(deps => {
        const [
            HarmonyMiniCartDialog
        ] = deps.map(dep => dep.default);

        return {
            listId: 'harmony.minicart',
            widgetsDefinition: () => [
                // modal
                ['minicartDialog', HarmonyMiniCartDialog, 'minicartDialog']
            ]
        };
    }));
}

if (pdp || plp || cart || wishlist) {
    WidgetsMgr.asyncListsPromises.push(Promise.all([
        import('harmony/product/HarmonyProductDetail'),
        import('harmony/product/HarmonyAddToCartMixin'),
        import('harmony/product/HarmonyVariationSwatch'),
        import('harmony/product/HarmonyProductImages'),
        import('harmony/product/ProductDescriptionItem'),
        import('harmony/wishlist/HarmonyAddToWishlistMixin'),
        import('harmony/product/ProductSet'),
        import('harmony/product/NotifyMeForm'),
        import('harmony/product/HarmonyProductTile')
    ]).then(deps => {
        const [
            HarmonyProductDetail,
            HarmonyAddToCartMixin,
            HarmonyVariationSwatch,
            HarmonyProductImages,
            ProductDescriptionItem,
            HarmonyAddToWishListMixin,
            ProductSet,
            NotifyMeForm,
            HarmonyProductTile
        ] = deps.map(dep => dep.default);

        return {
            listId: 'harmony.product.widgets',
            widgetsDefinition: () => [
                ['productDetail', HarmonyProductDetail, 'productDetail'],
                    ['productDetail', HarmonyAddToCartMixin, 'productDetail'],
                    ['productDetail', HarmonyAddToWishListMixin, 'productDetail'],
                ['productSet', ProductSet],
                ['variationSwatch', HarmonyVariationSwatch, 'colorSwatch'],
                ['productImages', HarmonyProductImages, 'productImages'],
                ['productDescriptionItem', ProductDescriptionItem],
                ['productTile', HarmonyProductTile, 'productTile'],
                ['productTile', HarmonyAddToWishlistMixin, 'productTile'],
                ['notifyMeForm', NotifyMeForm, 'ajaxform']
            ]
        };
    }));
}

if (cart) {
    WidgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'harmony.cart' */ 'harmony/cart/HarmonyCartMgr'),
        import(/* webpackChunkName: 'harmony.cart' */ 'harmony/forms/HarmonyCouponForm')
    ]).then(deps => {
        const [
            HarmonyCartMgr,
            HarmonyCouponForm
        ] = deps.map(dep => dep.default);

        return {
            listId: 'harmony.cart',
            widgetsDefinition: () => [
                ['cartMgr', HarmonyCartMgr, 'cartMgr'],
                ['cartMgr', AccessibilityFocusMixin, 'cartMgr'],
                ['couponform', HarmonyCouponForm, 'couponform']
            ]
        };
    }));
}

if (account) {
    WidgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'harmony.account' */ 'harmony/global/HarmonyTabs'),
        /* eslint-disable */
        import(/* webpackChunkName: 'harmony.account' */ 'harmony/account/VerificationCodeForm'),
        /** eslint-enable */
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/ajax/LoginForm'),
        import(/* webpackChunkName: 'harmony.account' */ 'harmony/forms/ajax/HarmonyLoginForm'),
        import(/* webpackChunkName: 'harmony.account' */ 'harmony/account/HarmonyPaymentsList'),
        import(/* webpackChunkName: 'harmony.account' */ 'widgets/checkout/InputCreditCardNumber'),
        import(/* webpackChunkName: 'harmony.account' */ 'harmony/account/PaymentForm'),
        import(/* webpackChunkName: 'harmony.account' */ 'authorize/payment/AuthorizeNetPaymentMixin'),
        import(/* webpackChunkName: 'harmony.account' */ 'authorize/account/AuthorizeNetPaymentForm'),
        import(/* webpackChunkName: 'account.widgets' */ 'harmony/forms/HarmonyAddressBookForm'),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/global/ListAccessibility'),
        import(/* webpackChunkName: 'account.widgets' */ 'harmony/account/HarmonyAddressList'),
        import(/* webpackChunkName: 'account.widgets' */ 'harmony/checkout/HarmonyInputCreditCardNumber'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/RadioSelector')
    ]).then(deps => {
        const [
            HarmonyTabs,
            VerificationCodeForm,
            LoginForm,
            HarmonyLoginForm,
            HarmonyPaymentsList,
            InputCreditCardNumber,
            PaymentForm,
            AuthorizeNetPaymentMixin,
            AuthorizeNetPaymentForm,
            HarmonyAddressBookForm,
            ListAccessibility,
            HarmonyAddressList,
            HarmonyInputCreditCardNumber,
            RadioSelector
        ] = deps.map(dep => dep.default);

        return {
            listId: 'harmony.account',
            widgetsDefinition: () => [
                ['harmonyTabs', HarmonyTabs, 'tabs'],
                ['verificationCodeForm', VerificationCodeForm, 'ajaxform'],
                ['verificationCodeForm', ListAccessibility, 'verificationCodeForm'],
                ['loginForm', LoginForm, 'ajaxform'],
                ['harmonyLoginForm', HarmonyLoginForm, 'loginForm'],
                ['harmonyLoginForm', ListAccessibility, 'harmonyLoginForm'],
                ['profileDetailsForm', ProfileDetailsForm, 'ajaxform'],
                ['paymentList', HarmonyPaymentsList, 'paymentList'],
                ['inputCreditCardNumber', InputCreditCardNumber, 'basicInput'],
                ['inputCreditCardNumber', HarmonyInputCreditCardNumber, 'inputCreditCardNumber'],
                ['paymentsList', HarmonyPaymentsList, 'paymentsList'],
                ['paymentForm', PaymentForm, 'ajaxform'],
                ['authorizeNetPaymentForm', AuthorizeNetPaymentMixin, 'paymentForm'],
                ['authorizeNetPaymentForm', AuthorizeNetPaymentForm, 'authorizeNetPaymentForm'],
                ['harmonyAddressBookForm', HarmonyAddressBookForm, 'addressBookForm'],
                ['addressList', HarmonyAddressList, 'addressList'],
                ['radioSelector', RadioSelector, 'inputRadio']
            ]
        };
    }));
}

if (checkout) {
    // @ts-ignore
    WidgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'harmony.account' */ 'harmony/account/VerificationCodeForm'),
        import(/* webpackChunkName: 'harmony.checkout' */ 'harmony/checkout/HarmonyAddressCheckoutForm'),
        import(/* webpackChunkName: 'harmony.checkout' */ 'harmony/checkout/HarmonyCheckoutMgr'),
        import(/* webpackChunkName: 'harmony.checkout' */ 'harmony/checkout/HarmonyShippingFrom'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/ajax/LoginForm'),
        import(/* webpackChunkName: 'harmony.checkout' */ 'harmony/forms/ajax/HarmonyLoginForm'),
        import(/* webpackChunkName: 'harmony.checkout' */ 'harmony/checkout/HarmonyPaymentAccordion'),
        import(/* webpackChunkName: 'harmony.checkout' */ 'harmony/checkout/HarmonyBillingForm'),
        import(/* webpackChunkName: 'harmony.checkout' */ 'harmony/checkout/HarmonyPaymentAccordionItemCREDIT_CARD'),
        import(/* webpackChunkName: 'harmony.checkout' */ 'authorize/payment/AuthorizeNetPaymentMixin'),
        import(/* webpackChunkName: 'harmony.checkout' */ 'authorize/checkout/AuthorizeNetBillingForm'),
        import(/* webpackChunkName: 'harmony.checkout' */ 'shipCompliant/checkout/ShipCompliantCheckoutMgrMixin'),
        import(/* webpackChunkName: 'harmony.checkout' */ 'shipCompliant/checkout/ShipCompliantShippingFormMixin'),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/global/ListAccessibility'),
        import(/* webpackChunkName: 'account.widgets' */ 'harmony/checkout/HarmonyInputCreditCardNumber'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'harmony/forms/InputFutureShippingDate'),
        import(/* webpackChunkName: 'harmony.cart' */ 'harmony/forms/HarmonyCouponForm'),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/forms/CouponForm'),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/cart/PromoCodeDisclosure')
    ]).then(deps => {
        const [
            VerificationCodeForm,
            HarmonyAddressCheckoutForm,
            HarmonyCheckoutMgr,
            HarmonyShippingFrom,
            LoginForm,
            HarmonyLoginForm,
            HarmonyPaymentAccordion,
            HarmonyBillingForm,
            HarmonyPaymentAccordionItemCREDITCARD,
            AuthorizeNetPaymentMixin,
            AuthorizeNetBillingForm,
            ShipCompliantCheckoutMgrMixin,
            ShipCompliantShippingFormMixin,
            ListAccessibility,
            HarmonyInputCreditCardNumber,
            InputFutureShippingDate,
            HarmonyCouponForm,
            CouponForm,
            PromoCodeDisclosure
        ] = deps.map(dep => dep.default);

        return {
            listId: 'harmony.checkout',
            widgetsDefinition: () => [
                ['verificationCodeForm', VerificationCodeForm, 'ajaxform'],
                ['verificationCodeForm', ListAccessibility, 'verificationCodeForm'],
                ['addressCheckoutForm', HarmonyAddressCheckoutForm, 'addressCheckoutForm'],
                ['checkoutMgr', HarmonyCheckoutMgr, 'checkoutMgr'],
                ['shippingForm', HarmonyShippingFrom, 'shippingForm'],
                ['harmonyLoginForm', HarmonyLoginForm, 'checkoutLoginForm'],
                ['harmonyLoginForm', ListAccessibility, 'harmonyLoginForm'],
                ['loginForm', LoginForm, 'ajaxform'],
                ['paymentAccordion', HarmonyPaymentAccordion, 'paymentAccordion'],
                ['billingForm', HarmonyBillingForm, 'billingForm'],
                ['paymentAccordionItemCREDIT_CARD', HarmonyPaymentAccordionItemCREDITCARD, 'paymentAccordionItemCREDIT_CARD'],
                ['authorizeNetBillingForm', AuthorizeNetPaymentMixin, 'billingForm'],
                ['authorizeNetBillingForm', AuthorizeNetBillingForm, 'authorizeNetBillingForm'],
                ['checkoutMgr', ShipCompliantCheckoutMgrMixin, 'checkoutMgr'],
                ['shippingForm', ShipCompliantShippingFormMixin, 'shippingForm'],
                ['inputCreditCardNumber', HarmonyInputCreditCardNumber, 'inputCreditCardNumber'],
                ['inputFutureShippingDate', InputFutureShippingDate, 'inputText'],
                ['couponform', CouponForm, 'ajaxform'],
                    ['couponform', HarmonyCouponForm, 'couponform'],
                ['promoCodeDisclosure', PromoCodeDisclosure, 'disclosure']
            ]
        };
    }));
}

if (wishlist) {
    WidgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'wishlist.widgets' */ 'widgets/wishlist/WishlistMgr'),
        import(/* webpackChunkName: 'wishlist.widgets' */ 'widgets/wishlist/WishListItem'),
        import(/* webpackChunkName: 'wishlist.widgets' */ 'harmony/wishlist/HarmonyWishListItem'),
        import(/* webpackChunkName: 'wishlist.widgets' */ 'harmony/wishlist/HarmonyWishlistMgr')
    ]).then(deps => {
        const [WishlistMgr, WishListItem, HarmonyWishlistItem, HarmonyWishlistMgr] = deps.map(dep => dep.default);

        return {
            listId: 'wishlist.widgets',
            widgetsDefinition: () => [
                ['wishlistMgr', WishlistMgr],
                ['wishListItem', WishListItem],
                ['wishListItem', HarmonyWishlistItem, 'wishListItem'],
                ['wishListItem', QuickAddToCart, 'wishListItem'],
                ['wishlistMgr', HarmonyWishlistMgr, 'wishlistMgr']
            ]
        };
    }));
}

if (orderconfirmation) {
    WidgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'orderconfirmation.widgets' */ 'harmony/forms/ajax/ConfirmationCreateAccount'),
        import(/* webpackChunkName: 'harmony.account' */ 'harmony/account/VerificationCodeForm'),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/global/ListAccessibility')
    ]).then(deps => {
        const [
            ConfirmationCreateAccount,
            VerificationCodeForm,
            ListAccessibility
        ] = deps.map(dep => dep.default);

        return {
            listId: 'orderconfirmation.widgets',
            widgetsDefinition: () => [
                ['confirmationCreateAccount', ConfirmationCreateAccount, 'ajaxform'],
                ['verificationCodeForm', VerificationCodeForm, 'ajaxform'],
                ['verificationCodeForm', ListAccessibility, 'verificationCodeForm']
            ]
        };
    }));
}

if (storeLocator) {
    WidgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'storelocator.widgets' */ 'harmony/forms/ajax/WhereToBuyForm')
    ]).then(deps => {
        const [
            WhereToBuyForm,
        ] = deps.map(dep => dep.default);

        return {
            listId: 'storelocator.widgets',
            widgetsDefinition: () => [
                ['whereToBuyForm', WhereToBuyForm, 'ajaxform']
            ]
        };
    }));
}

if(clp) {
    WidgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/ajax/LoginForm'),
        import(/* webpackChunkName: 'harmony.checkout' */ 'harmony/forms/ajax/HarmonyLoginForm'),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/global/ListAccessibility'),
        /* eslint-disable */
        import(/* webpackChunkName: 'harmony.account' */ 'harmony/account/VerificationCodeForm'),
    ]).then(deps => {
        const [
            LoginForm,
            HarmonyLoginForm,
            ListAccessibility,
            VerificationCodeForm
        ] = deps.map(dep => dep.default);

        return {
            listId: 'clp.widgets',
            widgetsDefinition: () => [
                ['loginForm', LoginForm, 'ajaxform'],
                ['harmonyLoginForm', HarmonyLoginForm, 'loginForm'],
                ['harmonyLoginForm', ListAccessibility, 'harmonyLoginForm'],
                ['verificationCodeForm', VerificationCodeForm, 'ajaxform'],
                ['verificationCodeForm', ListAccessibility, 'verificationCodeForm'],
            ]
        };
    }));
}
